import { VisuallyHidden } from "@99tech/ui";
import { Box, Stack } from "@mui/material";
import React from "react";
import { CharacterBg, CharacterFg, LogoHeader, RegisterBtn } from "src/assets";
import { LineSupportButton } from "src/main/components";
import { createStyles } from "src/main/utils";

interface LoginProps extends React.PropsWithChildren {}

const LandingPage: React.FC<LoginProps> = (props) => {
  return (
    <Box
      maxWidth="sm"
      sx={{
        padding: "32px 16px 64px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "auto",
        overflowY: "auto",
      }}
    >
      <VisuallyHidden as="h1">Wow268</VisuallyHidden>
      <Stack sx={brandStyles.root}>
        <Box sx={brandStyles.logo}></Box>
      </Stack>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          gap: 1,
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Box
          component="a"
          target="_blank"
          sx={buttonStyles.registerBtn}
        />
      </Box>
      <Box sx={characterStyles.root}>
        <Box className="place-holder"></Box>
        <Box sx={characterStyles.bg}></Box>
        <Box sx={characterStyles.main}></Box>
      </Box>
      <LineSupportButton />
    </Box>
  );
};

const brandStyles = createStyles({
  root: {
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  logo: {
    width: "100%",
    maxWidth: "324.9px",
    height: "160px",
    backgroundImage: `url(${LogoHeader})`,
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    flexShrink: 0,
    transform: `translateY(-12px)`,
  },
});

const characterStyles = createStyles({
  root: {
    width: "100%",
    maxWidth: "341.7px",
    height: "363.5px",
    position: "relative",

    ".place-holder": {
      width: "100%",
      maxWidth: "341.7px",
      height: "363.5px",
      backgroundColor: "rgba(0,0,0,0)",
    },
  },
  bg: {
    width: "100%",
    maxWidth: "341.7px",
    height: "269.7px",
    position: "absolute",
    backgroundImage: `url(${CharacterBg})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    flexShrink: 0,
    zIndex: 0,
    bottom: "28.38px",
    animation: "jumpy 1.6s infinite",
    animationTimingFunction: "linear",
  },
  main: {
    top: 0,
    left: "18.35px",
    width: "100%",
    maxWidth: "305px",
    height: "363.5px",
    position: "absolute",
    zIndex: 1,
    backgroundImage: `url(${CharacterFg})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    flexShrink: 0,
  },
});

const buttonStyles = createStyles({
  registerBtn: {
    backgroundImage: `url(${RegisterBtn})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    height: "54px",
    width: "220px",
    cursor: "pointer",
  },
});

export default LandingPage;
