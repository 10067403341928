import { useLayoutEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { DEFAULT_ENTER_SITE_URL, ENTER_SITE_URL_MAP, FallbackRefCode } from "../constants";
import { addParams } from "../utils";
import useFbcid from "./useFbcid";

const useUrls = () => {
  const [urls, setUrls] = useState(["", "", FallbackRefCode[0]]);
  const [params] = useSearchParams();
  const { fbcid } = useFbcid();

  useLayoutEffect(() => {
    let hostname = window.location.hostname;
    if (hostname.startsWith("www.")) {
      hostname = hostname.replace("www.", "");
    }
    const ENTER_SITE_URL = ENTER_SITE_URL_MAP[hostname] ?? DEFAULT_ENTER_SITE_URL;

    const code = params.has("code") ? params.get("code") ?? undefined : undefined;

    const urlParams = {
      fbcid: fbcid ? fbcid : undefined,
      id: code,
    };

    const enterSiteUrl = addParams(ENTER_SITE_URL, {
      ...urlParams,
    });

    setUrls([enterSiteUrl, code ?? ""]);
  }, [params, fbcid]);

  return {
    enterSiteUrl: urls[0] as string,
    refCode: urls[1] as string,
  };
};

export default useUrls;
