import { CloseOutlined } from "@mui/icons-material";
import { Box, Dialog, DialogContent, DialogProps, DialogTitle, IconButton } from "@mui/material";
import React from "react";
import { createStyles } from "src/main/utils";

interface HelpGifDialogProps extends DialogProps {
  title: string;
  description?: string;
  src: string;
  fallbackSrc?: string;
  alt?: string;
}

const HelpGifDialog: React.FC<HelpGifDialogProps> = (props) => {
  const { onClose, title, src, fallbackSrc, alt, ...rest } = props;

  return (
    <Dialog
      sx={styles.root}
      onClose={onClose}
      {...rest}
      onClick={(event) => event.stopPropagation()}
    >
      <DialogTitle
        sx={styles.dialog}
        display="flex"
        alignItems="center"
        style={{ paddingRight: 16 }}
      >
        <Box flex={1}>{title}</Box>
        {typeof onClose === "function" && (
          <IconButton
            type="button"
            color="inherit"
            onClick={(event) => onClose(event, "escapeKeyDown")}
          >
            <CloseOutlined />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent sx={styles.dialog}>
        <Box sx={styles.image}>
          <video
            muted
            loop
            autoPlay
            playsInline
            controls={false}
          >
            <source src={src} />
            {typeof fallbackSrc === "string" && <source src={fallbackSrc} />}
          </video>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const styles = createStyles({
  root: {},
  dialog: {
    color: "#fff",
    backgroundColor: "#f05287",
  },
  image: {
    video: {
      width: "100%",
      height: "auto",
      borderRadius: 1,
      display: "block",
      pointerEvents: "none",
    },
  },
});

export default HelpGifDialog;
