import BigNumber from "bignumber.js";

export const BN_ZERO = new BigNumber(0);
export const BN_ONE = new BigNumber(1);

export const Paths = {
  Landing: {
    Index: "/",
  },
};

export const PUSH_NOTIFICATION_STORAGE_KEY = "push-notification-register-status";
export const REGISTERED = "REGISTERED";

export const DEFAULT_ENTER_SITE_URL = "https://m.w22688.com/";

export const ENTER_SITE_URL_MAP = {
  "w268r.com": "https://m.woww268.com/",
  "268wow.com": "https://m.woww268.com/",
  "wow826.com": "https://m.woww268.com/",
  "wow268w.com": "https://m.woww268.com/",
};

export const LineSupportLink = "https://wow268win.com/cs";

export * from "./codes";
export * from "./context";
