import React, { useContext } from "react";

export interface AppContextData {
  urls?: {
    enterSiteUrl: string;
    refCode: string;
  };

  isFbIab?: boolean;
  isLineIab?: boolean;
  isIosDevice?: boolean;
  isFullScreen?: boolean;
}

export const AppContext = React.createContext<AppContextData>({});

export const useAppContext = () => {
  return useContext(AppContext);
};
