import { Box, Container, ContainerProps } from "@mui/material";
import React, { useCallback, useContext } from "react";
import { AppContext } from "src/main/constants";
import { isMobile } from "src/main/utils";

interface ContentProps extends React.PropsWithChildren, ContainerProps {}

const Content: React.FC<ContentProps> = (props) => {
  const { children, ...containerProps } = props;
  const { urls } = useContext(AppContext);

  const handleClickPage = useCallback(() => {
    const enterSiteUrl = urls?.enterSiteUrl;
    if (enterSiteUrl) window.open(enterSiteUrl, "_blank", isMobile() ? "width=full,height=full" : "");
  }, [urls?.enterSiteUrl]);

  return (
    <Container
      {...containerProps}
      className="bg"
      maxWidth={false}
      onClick={handleClickPage}
    >
      <div className="pat"></div>
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          overflowX: "hidden",
          overflowY: "auto",
          top: 0,
          left: 0,
          zIndex: 1,
        }}
      >
        {children}
      </Box>
    </Container>
  );
};

export default Content;
